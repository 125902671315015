class App {

	private videoElm: HTMLVideoElement
	private videoPlayElm: HTMLButtonElement

	constructor(){
		this.init()
	}
	
	init(){
		const startButton = document.querySelector(".js-start")
		startButton.addEventListener("click", (e) =>{
			e.preventDefault()
			this.changeTheme("movie")
		})

		this.videoElm = document.querySelector(".js-video")
		if(this.videoElm){
			this.videoElm.addEventListener("ended", ()=>{
				this.changeMovieState("played")
			})
		}

		this.videoPlayElm = document.querySelector(".js-play")
		if(this.videoPlayElm){
			this.videoPlayElm.addEventListener("click", () =>{
				this.playVideo()
			})
		}

		this.videoPlayElm = document.querySelector(".js-replay")
		if(this.videoPlayElm){
			this.videoPlayElm.addEventListener("click", () =>{
				this.playVideo()
			})
		}
	}

	changeTheme(themeName: string){
		document.body.setAttribute("data-theme", themeName)
	}

	changeMovieState(state: string){
		document.body.setAttribute("data-moviestate", state)
	}

	playVideo(){
		if(this.videoElm && this.videoElm.readyState > 0){
			this.videoElm.currentTime = 0
			this.videoElm.play()
			this.changeMovieState("play")
		}
	}

}

window.addEventListener("DOMContentLoaded", ()=>{
	new App()
})